@use "~@angular/material" as mat;

$general-typography: mat.define-typography-config(
  $font-family: 'Roboto , "Open Sans", "Helvetica Neue", sans-serif',
  $body-2: mat.define-typography-level(14px, 24px, 600),
);

$secondary-typography: mat.define-typography-config(
  $font-family: 'Roboto , "Open Sans", "Helvetica Neue", sans-serif',
);

// Be sure that you only ever include this mixin once!
@include mat.core($general-typography);

$my-app-primary: mat.define-palette(mat.$indigo-palette);
$my-app-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
$my-app-warn: mat.define-palette(mat.$red-palette);

$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include mat.all-component-themes($my-app-theme);

.grey-alternate-theme {
  $grey-alternate-primary: mat.define-palette(mat.$blue-grey-palette, 800);
  $grey-alternate-accent: mat.define-palette(mat.$yellow-palette, 400);

  $grey-alternate-theme: mat.define-dark-theme($grey-alternate-primary, $grey-alternate-accent);
  color: rgba(150, 150, 150, 0.8);

  @include mat.core-theme($grey-alternate-theme);

  @include mat.toolbar-typography($secondary-typography);
}
